<template>
  <v-container fluid>
    <PageNavigation :items="items" />

    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.complaintRecord')"
          icon="mdi-account"
        >
          <v-row class="mt-0">
            <v-col cols="12" lg="2" md="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.search')"
                v-model="search"
                append-icon="mdi-magnify"
              />
            </v-col>

            <v-col cols="12" lg="3" md="6">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                :nudge-top="30"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    label="Start Date ~ End Date"
                    prepend-icon=""
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="date = null"
                  />
                </template>

                <v-date-picker
                  v-model="dates"
                  range
                  @change="dateChange"
                  @input="dates.length == 2 ? (menu = false) : ''"
                  class="ma-0 pa-0"
                />
              </v-menu>
            </v-col>

            <v-col cols="12" lg="2" md="12">
              <v-select
                :items="All"
                :item-text="(item) => item.text"
                :item-value="(item) => item.value"
                @change="(item) => statusFilter(item)"
                label="All"
              ></v-select>
            </v-col>

            <v-spacer />

            <v-col
              cols="12"
              lg="2"
              md="12"
              align-self="center"
              class="d-flex justify-end"
              v-if="permissionCheck('add')"
            >
              <v-btn color="green" elevation="2" @click="handleDialog">
                <v-icon left>mdi-account-plus</v-icon>
                {{ $vuetify.lang.t("$vuetify.add") }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <GeneralTable
                :head="headers"
                :data="datas"
                :search="search"
                :dialog.sync="dialog"
                v-on:edititem="handleDialogEdit"
                v-on:deleteitem="handleDeleteDialog"
              />
            </v-col>
          </v-row>

          <ComplaintCRUD
            :dialog.sync="dialog"
            v-on:selectOrder="dialogOrder = true"
            @handleData="handleComplaintRecord"
            :orderId.sync="orderId"
            :myObj.sync="obj"
            :SelectedOrder.sync="SelectedOrder"
            :isBtnSearchOrder.sync="isBtnSearchOrder"
          />

          <SelectOrder
            :dialogOrder.sync="dialogOrder"
            @getselectedOrder="handleSelectOrder"
          />

          <DialogDelete
            :dialogDelete.sync="dialogDelete"
            :deleteObj="deleteObj"
            @handleDelete="handleDeleteConfirm"
          />
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const clone = (obj) => Object.assign({}, obj);
const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object);
  const targetKey = clonedObj[key];

  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
};

import {
  orderComplaintList,
  orderComplaintUpsert,
  orderComplaintDel,
} from "@/api/order";

const newUpData = () => {
  return {
    id: "",
    order_id: "",
    complaint_goods: "", // [{id:,nick_name:,number}]
    description: "", // 问题描述
    complaint_date: "", // 投诉日期（YYYY-mm-dd）
    receive_date: "", // 收到退卡日期
    is_reply: false, // 是否恢复客户 bool
    do_way: "", // 处理办法
    status: 0, // 是否结案 0 ， 1
  };
};

const newObj = () => {
  return {
    complaint_date: "",
    complaint_goods: [
      {
        code: "",
        id: "",
        image: "",
        is_gift: false,
        name: "",
        nick_name: "",
        number: 1,
        other_code: "",
        out_id: "",
        price: 0,
        purchase_number: 0,
        receive_number: 0,
        refund_qty: 0,
        refund_total: 0,
        type_name: "",
        unit: "",
      },
    ],
    customer_info: {
      company: "",
      contact: "",
      customer_id: "",
      tel: "",
    },
    description: "",
    do_way: "",
    gen_time: "",
    id: "",
    is_reply: true,
    itemNo: 1,
    order_id: "",
    order_po: "",
    receive_date: "",
    status: 1,
    user_info: {
      mobile: "",
      name: "",
      user_id: "",
    },
  };
};

const newDelete = () => {
  return {
    complaint_date: "",
    complaint_goods: [
      {
        code: "",
        id: "",
        image: "",
        is_gift: false,
        name: "",
        nick_name: "",
        number: 1,
        other_code: "",
        out_id: "",
        price: 0,
        purchase_number: 0,
        receive_number: 0,
        refund_qty: 0,
        refund_total: 0,
        type_name: "",
        unit: "",
      },
    ],
    customer_info: {
      company: "",
      contact: "",
      customer_id: "",
      tel: "",
    },
    description: "",
    do_way: "",
    gen_time: "",
    id: "",
    is_reply: true,
    itemNo: 1,
    order_id: "",
    order_po: "",
    receive_date: "",
    status: 1,
    user_info: {
      mobile: "",
      name: "",
      user_id: "",
    },
  };
};

const newSearch = () => {
  return {
    status: -1, // 状态（0 未处理，1已结案）
    order_id: "",
    customer_id: "",
    skip: 0,
    bt: "",
    et: "",
    limit: 10,
    opt: "",
  };
};

export default {
  name: "ComplaintRecord",
  components: {
    GeneralTable: () => import("@/components/tableImg"),
    ComplaintCRUD: () => import("@/components/orders/complaintCRUD"),
    SelectOrder: () => import("@/components/orders/selectOrder"),
    PageNavigation: () => import("@/components/pageNavigation"),
    DialogDelete: () => import("@/components/deleteDialog"),
  },
  data() {
    return {
      dialog: false,
      dialogOrder: false,
      dialogDelete: false,
      menu: false,
      isBtnSearchOrder: false,
      search: "",
      orderId: "",
      searchData: newSearch(),
      SelectedOrder: {},
      obj: newObj(),
      deleteObj: newDelete(),
      updataData: newUpData(),
      datas: [],
      dates: [],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          width: "100px",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.orderNo"),
          value: "order_po",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.customerordernumber"),
          value: "complaint_goods_name",
          width: "400px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.complaintNumber"),
          value: "complaint_goods_number",
          align: "center",
          width: "180px",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.customer"),
          value: "customer_info.contact",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.des"),
          value: "description",
          width: "200px",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.complaintDate"),
          value: "complaint_date",
          width: "160px",
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.dateOfReceipt"),
          value: "receive_date",
          width: "180px",
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.whetherRestoreCustomer"),
          value: "is_reply",
          width: "250px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.handlingMethod"),
          value: "do_way",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.whetherRestoreCustomer"),
          value: "status",
          width: "220px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      All: [
        { text: "All", value: -1 },
        { text: "Untreated", value: 0 },
        { text: "Closed", value: 1 },
      ],
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.orderMgr") },
        { text: this.$vuetify.lang.t("$vuetify.complaintRecord") },
      ],
    };
  },
  methods: {
    getData() {
      orderComplaintList(this.searchData)
        .then((data) => {
          if (data.items != null) {
            this.datas = [...data.items];
            for (var i = 0; i < this.datas.length; i++) {
              this.datas[i].itemNo = i + 1;
              this.datas[i].complaint_goods_name = this.datas[
                i
              ].complaint_goods.map((goods) => goods.name);
              this.datas[i].complaint_goods_number = this.datas[
                i
              ].complaint_goods.map((goods) => goods.number);
            }
          } else {
            this.datas = [];
            this.$toast.info("No Data Found", { timeout: 2000 });
          }
        })
        .catch((err) => console.log(err));
    },
    handleComplaintRecord(obj) {
      const arr = [];

      if (obj.products.length) {
        obj.products.forEach((g) =>
          arr.push({
            id: g.id,
            number: parseFloat(g.number),
            nick_name: g.nick_name,
            price: g.price,
          })
        );
      }

      this.dialog = false;

      const data = JSON.parse(JSON.stringify(obj));

      this.updataData.id = obj.order_id ? obj.id : "";
      this.updataData.order_id = obj.order_id || obj.id;
      this.updataData.complaint_goods = JSON.stringify(arr);
      this.updataData.complaint_date =
        obj.complaint_date || this.moment().format("YYYY-MM-DD");
      this.updataData.description = obj.description;
      this.updataData.receive_date = obj.receive_date;
      this.updataData.is_reply = obj.is_reply;
      this.updataData.do_way = obj.do_way;
      this.updataData.status = obj.status;

      orderComplaintUpsert(this.updataData)
        .then(() => {
          this.getData();
        })
        .catch((err) => console.log("New complaint: ", err));
    },
    handleDialog() {
      this.dialog = true;
      this.obj = {};
      this.SelectedOrder = {};
      this.isBtnSearchOrder = true;
    },
    handleSelectOrder(obj) {
      this.orderId = obj[0].po;
      this.obj = renameKey(obj[0], "po", "order_po");
      this.SelectedOrder = obj[0];
    },
    handleDialogEdit(obj) {
      if (this.permissionCheck("modify")) {
        this.dialog = true;
        this.obj = obj;

        this.obj = renameKey(this.obj, "complaint_goods", "products");

        var completeData = Object.assign({}, this.obj, this.SelectedOrder);

        this.SelectedOrder = completeData;
        this.isBtnSearchOrder = false;
      }
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.deleteObj = obj;
    },
    handleDeleteConfirm(obj) {
      this.dialogDelete = false;

      orderComplaintDel(obj.id)
        .then(() => {
          this.datas.splice(this.datas.indexOf(obj), 1);
        })
        .catch((err) => console.log("Error", err));
    },
    dateChange() {
      this.searchData.bt = Array.isArray(this.dates) ? this.dates[0] : "";
      this.searchData.et = Array.isArray(this.dates) ? this.dates[1] : "";

      this.getData();
    },
    statusFilter(value) {
      this.searchData.status = value;

      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    dateRangeText: {
      get: function () {
        return this.dates.join(" ~ ");
      },
      set: function (value) {
        if (value == null) {
          this.searchData = newSearch();

          this.getData();
        }
      },
    },
  },
};
</script>
